"use client";

import { useSearchParams, useRouter } from "next/navigation";
import { useState, useEffect } from "react";
import { toast } from "@/lib/sonner";
import { Button, Loading } from "@/lib/ui";
import { usePostHog } from "posthog-js/react";
import { useSession, signIn } from "next-auth/react";
import { Info } from 'lucide-react'
import { useTranslation } from "@sajilni/localization";
export default function LoginButton() {
  const { t } = useTranslation("sajilni2.0");
  const [loading, setLoading] = useState(false);
  // Get error message added by next/auth in URL.
  const searchParams = useSearchParams();
  const error = searchParams?.get("error");
  const posthog = usePostHog();
  const { data: session, status } = useSession();
  useEffect(() => {
    const errorMessage = Array.isArray(error) ? error.pop() : error;
    errorMessage && toast.error(errorMessage, {
      className: "error-toast", icon: <Info width={15} height={15} />
    });
  }, [error]);
  // useEffect(() => {
  //   if (status === "authenticated" && session?.user?.email) {
  //     posthog.identify(session?.user?.email);
  //   }
  // }, [status]);
  return (
    <Button
      disabled={loading}
      onClick={() => {
        setLoading(true);
        signIn("google").then(() =>
          posthog.identify(session?.user?.email as string)
        );
      }}
      variant="solid"
      className="bg-white shadow-md w-full text-xl text-[#757575] font-medium gap-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <rect width="24" height="24" transform="translate(0.5)" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.54 12.2614C23.54 11.4459 23.4668 10.6618 23.3309 9.90909H12.5V14.3575H18.6891C18.4225 15.795 17.6123 17.013 16.3943 17.8284V20.7139H20.1109C22.2855 18.7118 23.54 15.7636 23.54 12.2614Z" fill="#4285F4" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 23.4998C15.605 23.4998 18.2081 22.4701 20.1109 20.7137L16.3943 17.8283C15.3645 18.5183 14.0472 18.926 12.5 18.926C9.50474 18.926 6.96951 16.903 6.06519 14.1848H2.22314V17.1644C4.11542 20.9228 8.00451 23.4998 12.5 23.4998Z" fill="#34A853" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.06523 14.1851C5.83523 13.4951 5.70455 12.758 5.70455 12.0001C5.70455 11.2421 5.83523 10.5051 6.06523 9.81506V6.83551H2.22318C1.44432 8.38801 1 10.1444 1 12.0001C1 13.8557 1.44432 15.6121 2.22318 17.1646L6.06523 14.1851Z" fill="#FBBC05" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 5.07386C14.1884 5.07386 15.7043 5.65409 16.8961 6.79364L20.1945 3.49523C18.2029 1.63955 15.5997 0.5 12.5 0.5C8.00451 0.5 4.11542 3.07705 2.22314 6.83545L6.06519 9.815C6.96951 7.09682 9.50474 5.07386 12.5 5.07386Z" fill="#EA4335" />
      </svg>
      {loading ? <Loading /> : t("labels.signUpGoogle")}
    </Button>
  );
}