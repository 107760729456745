import { c, a } from './chunk-7ICUGDQU.mjs';
import * as u from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '@sajilni/utils';
import { jsx } from 'react/jsx-runtime';

var p=cva("ui-inline-flex ui-items-center ui-justify-center ui-rounded-md ui-text-sm ui-font-medium ui-ring-offset-white ui-transition-colors focus-visible:ui-outline-none focus-visible:ui-ring-2 focus-visible:ui-ring-slate-400 focus-visible:ui-ring-offset-2 disabled:ui-pointer-events-none disabled:ui-opacity-50 dark:ui-ring-offset-slate-950 dark:focus-visible:ui-ring-slate-800",{variants:{buttonColor:{primary:"!ui-bg-primary ui-text-slate-50 hover:!ui-bg-primary/90 dark:!ui-bg-primary-50 dark:ui-text-primary dark:hover:!ui-bg-primary-50/90",secondary:"!ui-bg-secondary ui-text-slate-900 hover:ui-bg-secondary-100/80 dark:ui-bg-secondary-800 dark:ui-text-slate-50 dark:hover:ui-bg-secondary-800/80",danger:"!ui-bg-red-500 ui-text-slate-50 hover:ui-bg-red-500/90 dark:ui-bg-red-900 dark:ui-text-red-50 dark:hover:ui-bg-red-900/90",gray:"!ui-bg-secondary ui-text-secondary-foreground hover:ui-bg-secondary/80"},variant:{solid:i=>i||"primary",outlined:"ui-border ui-border-slate-200 ui-bg-white hover:ui-bg-slate-100 hover:ui-text-slate-900 dark:ui-border-slate-800 dark:ui-bg-slate-950 dark:hover:ui-bg-slate-800 dark:hover:ui-text-slate-50",ghost:"hover:ui-bg-slate-100 hover:ui-text-slate-900 dark:hover:ui-bg-slate-800 dark:hover:ui-text-slate-50",link:"ui-text-slate-900 ui-underline-offset-4 hover:ui-underline dark:ui-text-slate-50"},size:{default:"ui-h-10 ui-px-4 ui-py-2",sm:"ui-h-9 ui-rounded-md ui-px-3",lg:"ui-h-11 ui-rounded-md ui-px-8",icon:"ui-h-10 ui-w-10"}},defaultVariants:{variant:"solid",size:"default"}}),m=u.forwardRef((c$1,l)=>{var e=c$1,{className:i,variant:o,size:a$1,asChild:s=!1,buttonColor:n}=e,d=c(e,["className","variant","size","asChild","buttonColor"]);return jsx(s?Slot:"button",a({className:cn(i,p({variant:o,size:a$1,buttonColor:n,className:i})),ref:l},d))});m.displayName="Button";

export { p as a, m as b };
