import { c as c$1, b as b$1, a } from './chunk-7ICUGDQU.mjs';
import * as c from 'react';
import * as e from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { cn } from '@sajilni/utils';
import { jsx } from 'react/jsx-runtime';

var b=c.forwardRef((f,m)=>{var t=f,{className:u}=t,d=c$1(t,["className"]);return jsx(e.Root,b$1(a({ref:m,className:cn("ui-peer ui-h-4 ui-w-4 ui-shrink-0 ui-rounded-sm ui-border ui-border-slate-200  ui-ring-offset-white focus-visible:ui-outline-none  focus-visible:ui-ring-1 focus-visible:ui-ring-primary focus-visible:ui-border-primary disabled:ui-cursor-not-allowed disabled:ui-opacity-50 data-[state=checked]:ui-bg-primary data-[state=checked]:ui-text-slate-50 dark:ui-border-slate-800 dark:ui-border-slate-50 dark:ui-ring-offset-slate-950 dark:focus-visible:ui-ring-slate-800 dark:data-[state=checked]:ui-bg-slate-50 dark:data-[state=checked]:ui-text-slate-900",u)},d),{children:jsx(e.Indicator,{className:cn("ui-flex ui-items-center ui-justify-center ui-text-current"),children:jsx(Check,{className:"h-4 w-4"})})}))});b.displayName=e.Root.displayName;

export { b as a };
